'use client';

import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import * as Sentry from '@sentry/nextjs';
import { usePathname, useSearchParams } from 'next/navigation';
import { usePostHog } from 'posthog-js/react';
import { useSession } from 'next-auth/react';
import { useEffect } from 'react';
import { SessionProvider } from 'next-auth/react';
import { Suspense } from 'react';

if (typeof window !== 'undefined') {
  posthog.init('phc_FUQnkPs7feF13P5ZpOm4sc6Nu1jiGrwYceoOdsRcIVf', {
    api_host: 'https://app.posthog.com',
    capture_pageview: false, // Disable automatic pageview capture, as we capture manually
  });
}

function PageViewProvider({ children }: { children: React.ReactNode }) {
  const { data } = useSession();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const posthog = usePostHog();

  useEffect(() => {
    if (data) {
      posthog.identify(data.user.user_id?.toString(), {
        email: data.user.email,
        name: data.user.name,
        customer_name: data.user.customer_name,
        customer_id: data.user.customer_id,
        logo_url: data.user.logo_url,
        stripe_connected_account_id: data.user.stripe_connected_account_id,
      });

      Sentry.setUser({
        email: data.user.email,
      });
    }
    if (pathname && posthog) {
      let url = window.origin + pathname;
      if (searchParams?.toString()) {
        url = url + `?${searchParams.toString()}`;
      }
      posthog.capture('$pageview', {
        $current_url: url,
      });
    }
  }, [data, pathname, searchParams, posthog]);
  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}

export default function Providers({ children }: { children: React.ReactNode }) {
  return (
    <SessionProvider>
      <Suspense>
        <PageViewProvider>{children}</PageViewProvider>
      </Suspense>
    </SessionProvider>
  );
}
